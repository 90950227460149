import { UrlSiteBase } from '../../Global';
import { Route, Routes } from "react-router-dom";

let { UrlBase } = UrlSiteBase();
const NavTitlesGTS = (): JSX.Element => {
  return (
    <>
    <h3 className='title'>
      <Routes>
      <Route path={UrlBase} element={""} />
        <Route path={UrlBase + 'Usuario'} element={"Usuarios"} />
        <Route path={UrlBase + 'Usuario/Agregar'} element={"Agrega usuario"} />
        <Route path={UrlBase + 'Usuario/Editar/:usuarioId?'} element={"Editar usuario"} />
        <Route path={UrlBase + 'Perfil'} element={"Perfiles"} />
        <Route path={UrlBase + 'Perfil/Agregar'} element={"Agrega perfil"} />
        <Route path={UrlBase + 'Perfil/Editar/:perfilId?'} element={"Editar perfil"} />
        <Route path={UrlBase + 'Permisos'} element={"Editar permisos"} />
        <Route path={UrlBase + 'Productos'} element={"Productos"} />
        <Route path={UrlBase + 'Producto/Agregar'} element={"Agregar Producto"} />
        <Route path={UrlBase + 'Producto/Editar/:productoId?'} element={"Editar Producto"} />
        <Route path={UrlBase + 'Clientes'} element={"Clientes"} />
        <Route path={UrlBase + 'Cliente/Agregar'} element={"Agregar un Cliente"} />
        <Route path={UrlBase + 'Cliente/Editar/:clienteId?'} element={"Editar Cliente"} />
        <Route path={UrlBase + 'Clientes-Prospecto'} element={"Clientes Prospecto"} />
        <Route path={UrlBase + 'Cliente-Prospecto/Agregar'} element={"Agregar un Cliente Prospecto"} />
        <Route path={UrlBase + 'Cliente-Prospecto/Editar/:clienteId?'} element={"Editar Cliente Prospecto"} />
        <Route path={UrlBase + 'Inventario'} element={"Inventario de la distribuidora"} />
        <Route path={UrlBase + 'Lista-Precios'} element={"Lista de precios"} />
        <Route path={UrlBase + 'Venta'} element={"Nueva Venta"} />
        <Route path={UrlBase + 'Venta-Almacenada'} element={"Ventas Almacenadas"} />
        <Route path={UrlBase + 'Venta-Almacenada/Cliente/:idCliente?'} element={"Entrega de producto almacenado"} />
        <Route path={UrlBase + 'Reportes/PedidoTotal'} element={"Reporte de Pedidos"} />
        <Route path={UrlBase + 'Pedido/Agregar/:pedidoId?'} element={"Agregar un Pedido"} />
        <Route path={UrlBase + 'Producto'} element={"Productos"} />
        <Route path={UrlBase + 'logout'} element={""} />
        <Route path={UrlBase + 'login'} element={""} />
        <Route path={UrlBase + 'homepage'} element={""} />
      </Routes>
      </h3>
    </>
  )
}

export default NavTitlesGTS;
