export interface IVenta {
  idCliente: number,
  tipoVenta: number,
  productos: IVentaProducto[],
  observaciones: string,
  ventaAlmacenada: boolean,
  productosAlmacenados: IVentaProducto[],
}
export interface IVentaProducto {
  id: number,
  nombre: string,
  precio: number,
  cantidad: number,
  maxCantidad: number,
  cantidadEntrega: number
}
export const VentaDefault = {
  idCliente: 0,
  tipoVenta: 0,
  productos: [],
  observaciones: '',
  ventaAlmacenada: false,
  productosAlmacenados: []
}
export const VentaProductoDefault = {
  id: 0,
  nombre: '',
  precio: 0,
  cantidad: 0
}
export interface IVentaError {
  idCliente: string,
  tipoVenta: string,
  productos: string,
  observaciones: string,
  ventaAlmacenada: string,
  productosAlmacenados: string,
}
export const VentaError = {
  idCliente: '',
  tipoVenta: '',
  productos: '',
  observaciones: '',
  ventaAlmacenada: '',
  productosAlmacenados: ''
}