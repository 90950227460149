
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IVentaProducto } from "../../interfaces/venta-interface";
import { IGlobalPoprop, IServiceData } from "../../interfaces/global-interface";
import { getServiceData } from "../../store/global-services";
import ListProductGTS from "../../molecules/ListProductGTS";
import ListClientGTS from "../../molecules/ListClientGTS";
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import { TabsGTS, TabPanelGTS} from "../../molecules/TabsGTS";
import { ICliente, ClienteDefault  } from "../../interfaces/cliente-interface";
import { UrlSiteBase } from '../../Global';
let { UrlBase } = UrlSiteBase();

const VentaAlmacenada = (globalProp?: IGlobalPoprop) => {
    const { idCliente } = useParams();
    const [clienteVenta, setClienteVenta] = useState<ICliente>(ClienteDefault);
    const [prodAlmacenado, setProdAlmacenado] = useState<IVentaProducto[]>([]);
    const [prodAlmEntrega, setProdAlmEntrega] = useState<IVentaProducto[]>([]);
    const [tabSelected, setTabSelected] = useState<number>(0);
    const clienteLabel= ['Productos pendientes', 'Resumen'];
    const navigate = useNavigate();
    const handleTabChangeVenta = (idxTab: number) => {
        let bolContinue = true;
        if (idxTab === 1 && !tieneProdAEntregar()) {
            bolContinue = false;
            enviaMensajeAddProd();
        }
        if (bolContinue)
            setTabSelected(idxTab);
        return bolContinue;
    }
    const enviaMensajeAddProd = () => {
        const message = 'Agrega al menos un producto a entregar';
        if (globalProp && globalProp.snackHandleOpen)
            globalProp.snackHandleOpen({open: true, message: message, color: 'red'});
    }
    const entregarProductos = () => {
        if (tieneProdAEntregar())
            setTabSelected(1);
        else 
            enviaMensajeAddProd();
    }   
    const tieneProdAEntregar = () => {
        let prodAlmEnt = prodAlmacenado.filter(prod => prod.cantidadEntrega > 0);
        if (prodAlmEnt.length > 0)
            setProdAlmEntrega(prodAlmEnt);
        return prodAlmEnt.length > 0;
    }
    const guardarEntrega = () => {
        if (prodAlmEntrega.length > 0) {
             
        }
    }
    useEffect(() => {
        getServiceData({ id: idCliente, nombre : 'cliente' }).then(data => {
            if (data.success && data.cliente)
                setClienteVenta(data.cliente);
        })
        getServiceData({ nombre: 'venta-almacenada', parametros: { idCliente: idCliente }}).then(data => {
            if (data.success && data.venta_almacenada)
                setProdAlmacenado([...data.venta_almacenada]);
        });
    },[]);
    return(<>
    <ListClientGTS key="list-client-resume" screen="venta" subScreen="resume" clientes={[clienteVenta]} />
    <TabsGTS labels={ clienteLabel } handleTabChange={handleTabChangeVenta} tabSelected={0} >
        <TabPanelGTS index={0} key="tab-panel-0" value={tabSelected} screen="venta-almacenada" productos={prodAlmacenado}>
            <ListProductGTS  key="list-product-store" screen="venta-almacenada" productos={prodAlmacenado}  />
            <div className="panel-actions center">
                <ButtonGST onClick={() => navigate(UrlBase + 'Venta-Almacenada')}>Cancelar</ButtonGST>
                <ButtonGST onClick={entregarProductos}>Entregar productos</ButtonGST>
            </div>
        </TabPanelGTS>
        <TabPanelGTS index={1} key="tab-panel-1" value={tabSelected} screen="venta-almacenada" productos={prodAlmEntrega}>
            <ListProductGTS  key="list-product-store" screen="venta-almacenada-resume" productos={prodAlmEntrega}  />
            <div className="panel-actions center">
                <ButtonGST onClick={() => navigate(UrlBase + 'Venta')}>Cancelar</ButtonGST>
                <ButtonGST onClick={guardarEntrega}>Guardar Entrega de Producto</ButtonGST>
            </div>
        </TabPanelGTS>
    </TabsGTS>
    </>);    
}
export default VentaAlmacenada