import { IconButton, TextField, Pagination, Stack, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import { getServiceData } from "../../store/global-services";
import { ICliente } from "../../interfaces/cliente-interface";
import { UrlSiteBase } from '../../Global';
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import ListClientGTS from "../../molecules/ListClientGTS";
import { IGlobalPoprop, IServiceData } from "../../interfaces/global-interface";
let { UrlBase } = UrlSiteBase();

const ListadoVentasAlmacenadas = (globalProp?: IGlobalPoprop) => {
  const [clientes, setClientes] = useState<ICliente[]>();
  const [textSearch, setClienteBusqueda] = useState<string>('');  
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  
  const navigate = useNavigate();
  
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') { 
      getClientesSearch(); 
      event.preventDefault(); 
    }
  };
  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setClienteBusqueda(value);
  }
  const getClientesSearch = () => {
    getClientes(1);
  }
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const handleClick = (clientId: number) => {
    navigate(`${UrlBase}Venta-Almacenada/Cliente/${clientId}`);
  };
  const getClientes = (page: number) => {
    let dataService: IServiceData  = { nombre: 'clientes' };
    let paramService = {};
    if (page > 1) 
      paramService = { ...paramService, 'page': page };
    if (textSearch.trim().length > 0)
      paramService = { ...paramService, 'search': textSearch.trim() };
    dataService = { ...dataService, 'parametros': paramService };
    getServiceData(dataService).then(data => {
      if (data.success){
        setClientes(data.clientes);
        setTotalPages(data.pagination.last_page);
      }
    });
  };
  useEffect(() => {
    getClientes(page);
  }, [page]);

  return (
    <>
      <div className="panel-search">
        <TextField id="search" onChange={handleSearchTextChange} value={textSearch} label="Buscar" color="secondary"
          InputProps={{ endAdornment: 
            <InputAdornment position="end">
              <IconButton onClick={getClientesSearch}>
                <SearchIcon />
              </IconButton>
            </InputAdornment> }} onKeyDown={handleKeyDown}/>
      </div>
      <ListClientGTS clientes={clientes} handleClick={handleClick} />
      { totalPages > 1 ?
        <Stack spacing={2}>
          <Pagination count={totalPages} page={page} onChange={handlePaginationChange} />
        </Stack>
        : <></>
      }
    </>
  )
}

export default ListadoVentasAlmacenadas;